import React from 'react'
import loader from "./loader.gif"


const Loader = () => {
  return (
    <div style={{width: "100vw", height: "100vh", backgroundColor: "#FFF6F1", display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column", overflow:"hidden"}}>
      <img src={loader} alt="" />
      <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:"0.3rem"}}>
      <h1 className='bold'>Felix</h1>
      <p style={{ fontSize:"23px", color:"#04ad86"}} className='text'>Hawk</p>
      </div>
    </div>
  )
}

export default Loader