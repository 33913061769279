
function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message} API limit exceeded please try after some time</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}




export default ErrorFallback